import { CampaignWizard, NewBreadcrumb, SpinLoader } from "components";
import IOSPreview from "components/Device/IOSPreview/IOSPreview";
import PrimaryButton from "components/Form/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "components/Form/Buttons/SecondaryButton/SecondaryButton";
import langs from "langs";
import _ from "lodash";
import { isIOSConfigured } from "modules/checkPushConfig";
import { getDefaultTimezoneFromSuit } from "modules/helpers";
import uploadToImgur from "modules/imgur";
import {
  createInitConfig,
  handleChangeConfig,
  shouldWizardChange,
} from "modules/push";
import { hasAccessToProduct, hasProduct } from "modules/user";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Notifications from "react-notification-system-redux";
import { I18n } from "react-redux-i18n";
import { clearPending, sendPush, sendPushScheduler } from "reducers/AppPush";
import { getSuitCustomerSegments } from "reducers/CustomerSegment";
import { getSuitLandingPages } from "reducers/MicroPages";
import { duplicateCampaign } from "reducers/push";
import { getSuitSmartlinks } from "reducers/smartlink";
import { getSuitTemplates } from "reducers/template";
import { getAppBackendConfig, getCurrentSuit } from "reducers/user";
import { change as ReduxChange, destroy } from "redux-form";
import { RoutedComponent, connect } from "routes/routedComponent";
import { isAndroidConfigured } from "../../../../modules/checkPushConfig";
import { getNotifyConfig } from "../../../../reducers/config";
import classes from "./CreateAppPush.scss";
import {
  PushContentStep,
  PushMessageStep,
  PushOptionsStep,
  PushPublishStep,
} from "./components";
import LanguageSelector from "./components/LanguageSelector";

let sequence = ["message", "content", "options", "publish"];

let default_steps = [
  { label: I18n.t("Info"), width: 25, value: "info" },
  { label: I18n.t("Content"), width: 25, value: "content" },
  { label: I18n.t("options"), width: 25, value: "options" },
  { label: I18n.t("publish"), width: 25, value: "publish" },
];

let silentSteps = [
  { label: I18n.t("Info"), width: 25, value: "info" },
  { label: I18n.t("Content"), width: 25, value: "content" },
  { label: I18n.t("publish"), width: 25, value: "publish" },
];

let steps = [
  { label: I18n.t("Info"), width: 25, value: "info" },
  { label: I18n.t("Content"), width: 25, value: "content" },
  { label: I18n.t("options"), width: 25, value: "options" },
  { label: I18n.t("publish"), width: 25, value: "publish" },
];

var myuserId = null;

class CreatePushContainer extends RoutedComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: {},
      initialized: false,
      videoSelection: {
        label: I18n.t("createAppPushPage_Portrait_label"),
        value: "portrait",
      },
      selectedTemplate: _.chain(langs.all())
        .mapValues((lang) => ({
          code: lang[1],
        }))
        .mapKeys("code")
        .mapValues(() => ({
          label: I18n.t("SelectTemplate"),
          value: "noTemplate",
        }))
        .value(),
      appPushGif: "",
      fileSizeError: false,
      validation: true,
      currStepIdx: 1,
      currentStep: sequence[0],
      advancedOpen: {},
      type: "appPush",
      urlType: { label: I18n.t("URL"), value: "url" },
      urlValue: { label: I18n.t("URL"), value: "url" },
      selectedSegment: {
        label: "",
        value: "",
      },
      targetType: {
        value: "noTarget",
        label: I18n.t("allusersAndDevices"),
      },
      optionals: {
        expire_date: null,
        title: null,
        badge: null,
        custom_sound: null,
        ios_category: null,
      },
      optionsLoaded: false,
      selectedUrl: "",
      selectedDevice: {
        label: I18n.t("Android"),
        value: "android",
      },
      sendNow: true,
      repeat: false,
      time: {
        timezone: "",
        start: "",
        end: "",
        frequency: "day",
      },
      optionUrlType: {
        label: I18n.t("URL"),
        value: "url",
      },
      optionUrlValue: null,
      imgLoading: {},
      vidLoading: {},
      checkimgUplud: false,
      checkvidUplud: false,
      localization: "english",
      urlTypeForOptionalImage: {
        label: I18n.t("URL"),
        value: "url",
      },
      urlValueForOptionalImage: null,
      languages: _.mapKeys(
        langs.all().map((lang) => ({
          name: lang.name,
          code: lang[1],
          selected: lang[1] === "en",
          important: lang[1] === "en",
        })),
        "code"
      ),
      selectedLocalization: { code: "en", name: "English" },
      duplicate: null,
      id: false,
      selectedType: null,

      loading: true,
    };

    !hasAccessToProduct(
      this.props.user.activeSuitId,
      "App Push Notification",
      this.props
    ) && this.context.router.push("/app/" + this.params.subDomain);
  }

  prepareFetchSegments = async () => {
    const {
      user: { appbackend, suit, suits, activeSuitId },
      getAppBackendConfig,
      getCurrentSuit,
      getSuitCustomerSegments,
    } = this.props;

    let currentSuit = suit || suits.find((suit) => suit.id === activeSuitId);

    let appBackend = appbackend;

    if (!appBackend) {
      const response = await getAppBackendConfig(
        currentSuit.subdomain,
        currentSuit.appbackend_userId
      );

      appBackend = response.value.body;
    }

    if (!currentSuit) {
      await getCurrentSuit(activeSuitId);
    }

    return getSuitCustomerSegments(
      appBackend.serverURL + "fe",
      appBackend.appId,
      appBackend.masterKey
    );
  };

  refetchSegments = async () => {
    await this.prepareFetchSegments();
  };

  async componentDidMount() {
    const {
      routeParams: { campaignId, action },
      user: { activeSuitId, suit, suits, appbackend },
      getSuitLandingPages,
      getSuitSmartlinks,
      getSuitTemplates,
      getAppBackendConfig,
      getCurrentSuit,
      showNotification,
      push,
      getNotifyConfig,
    } = this.props;

    if (push.duplicate) {
      const selectedType = push.duplicate.creation_payload.appPush.selectedType;

      this.setState({ selectedType });
    }

    let currentSuit = suit || suits.find((suit) => suit.id === activeSuitId);
    const typeName = "appPush";

    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("userId");
    const segmentId = urlParams.get("segmentId");
    let segment = null;
    let appBackend = appbackend;

    if (!appBackend) {
      const response = await getAppBackendConfig(
        currentSuit.subdomain,
        currentSuit.appbackend_userId
      );

      appBackend = response.value.body;
    }

    if (!currentSuit) {
      await getCurrentSuit(activeSuitId);
    }

    this.setState({
      data: createInitConfig(typeName, activeSuitId, currentSuit.api_key),
      type: typeName,
    });

    let promises = [];

    promises.push(this.prepareFetchSegments());
    promises.push(getNotifyConfig(activeSuitId));

    hasProduct(activeSuitId, "Mobile Landing Pages") &&
      promises.push(getSuitLandingPages(activeSuitId));

    hasProduct(activeSuitId, "Smart Deep Link") &&
      promises.push(getSuitSmartlinks(activeSuitId));

    promises.push(getSuitTemplates(activeSuitId, "appPush"));

    try {
      const response = await Promise.allSettled(promises);

      segment = response[0].value.value.body.result.data.filter(
        (segment) => segment.objectId === segmentId
      )[0];

      const notifyConfig = response[1].value.value.body;

      // check for android config
      if (!isAndroidConfigured(notifyConfig?.channels)) {
        showNotification(
          {
            title: "",
            message: I18n.t("createAppPushPage_androidErrorMsg"),
            position: "tc",
            autoDismiss: 5,
          },
          "error"
        );
      }
    } catch (err) {
      console.error(err);
    }

    // TODO: need to check if there are any pages redirects here with query params (userId or segment)
    if (userId) {
      this.setState({ targetType: "userId" });
      this.handleChange("action.appPush.targetUserIds", [{ userID: userId }]);
    } else if (segment) {
      this.setState({ targetType: { value: "segments", label: "Segments" } });
      this.handleSegmentSelect({
        label: segment.name,
        value: segment.objectId,
      });
      this.handleChange("action.appPush.segment", segment);
      this.handleChange("action.appPush.segmentId", segmentId);
    }

    if (campaignId && !this.state.initialized) {
      const { creation_payload } = this.props.push.duplicate;

      this.setState((prevState) => ({
        data: handleChangeConfig("action", creation_payload, prevState.data),
        optionals: handleChangeConfig(
          "optionals",
          creation_payload.appPush.optionals,
          prevState.optionals
        ),
        initialized: true,
      }));

      // TODO: while creating app push make sure that segment value is set correctly (the segment name)
      if (creation_payload.segmentId) {
        const segment = this.props.segments.find(
          (segment) => segment.objectId === segmentId
        );
        this.setState({
          segmentValue: {
            label: segment.name,
            value: segment.name,
          },
        });
      }

      if (creation_payload.appPush.localization) {
        const { languages: oldLanguages } = this.state;

        _.mapKeys(creation_payload.appPush.localization, (lang) => {
          if (lang !== undefined && lang.selected === true) {
            oldLanguages[lang.code].selected = true;
          }
        });
      }
      if (creation_payload.appPush.orientation) {
        if (creation_payload.appPush.orientation.toLowerCase() === "portrait") {
          this.setState({
            orientation: {
              label: I18n.t("createAppPushPage_Portrait_label"),
              value: "portrait",
            },
          });
        }
      }
    }

    const timezone = getDefaultTimezoneFromSuit(currentSuit);

    this.setState({
      time: {
        timezone: timezone || "",
        start: "",
        end: "",
        frequency: "day",
      },

      id: campaignId || false,
      action: action || "new",
    });

    // check for ios config
    if (!isIOSConfigured(appBackend)) {
      showNotification(
        {
          title: "",
          message: I18n.t("createAppPushPage_IOSErrorMsg"),
          position: "tc",
          autoDismiss: 5,
        },
        "error"
      );
    }

    this.setState({ loading: false });
  }

  changeVideoOrientation = (videoSelection) => {
    this.setState({ videoSelection });
  };

  handleOptionalUrlImageSelect = (label, value) => {
    this.setState({ urlValueForOptionalImage: { label, value } });
  };

  handleOptionalUrlImageSelectType = (label, value) => {
    this.setState({ urlTypeForOptionalImage: { label, value } });
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  changeLocalization = (localization) => {
    this.setState({ localization });
  };

  onClickLanguage = (code) => {
    const { languages: oldLanguages, selectedLocalization } = this.state;
    if (oldLanguages[code].selected && selectedLocalization.code === code) {
      // reset to default localization if you remove hightlighted selected localization
      this.setLocalization({ code: "en", name: "English" });
    }
    this.setState({
      languages: {
        ...oldLanguages,
        [code]: {
          ...oldLanguages[code],
          selected: !oldLanguages[code].selected,
        },
      },
    });
  };

  clearImgur = (type) => {
    if (type === `action.appPush.image-url.src`) {
      this.handleChange("action.appPush.image-url", undefined);
    } else {
      this.handleChange("action.appPush.attachment", undefined);
    }
    this.setState({ checkimgUplud: false });
  };

  fileSizeCheck = (file) => {
    return file.size / 1000 <= 500;
  };

  handleUploadImg = (type, e, value) => {
    const { ReduxChange } = this.props;

    if (!this.fileSizeCheck(value)) {
      this.setState({ fileSizeError: true });
      return;
    }
    this.setState({ fileSizeError: false });
    if (value !== undefined) {
      this.setState((prevState) => ({
        imgLoading: { ...prevState.imgLoading, [type]: true },
        vidLoading: { ...prevState.vidLoading, [type]: true },
      }));
      uploadToImgur(type, this.props, value, (data) => {
        this.setState((prevState) => ({
          imgLoading: { ...prevState.imgLoading, [type]: false },
          vidLoading: { ...prevState.vidLoading, [type]: false },
        }));
        if (_.get(data, ["data", "link"], false)) {
          this.handleChange(type, data.data.link);

          if (type === "action.appPush.image-url") {
            this.handleChange("action.appPush.image-url.name", value.name);
            this.handleChange("action.appPush.image-url.src", data.data.link);
            ReduxChange(
              "pushContentStep",
              "action.appPush.image-url.src",
              data.data.link
            );
            ReduxChange(
              "pushContentStep",
              "action.appPush.image-url.name",
              value.name
            );
          } else if (type === "action.appPush.image-url.src") {
            this.handleChange("action.appPush.image-url.name", value.name);
          } else if (type === "action.appPush.appPushVideo") {
            this.handleChange("action.appPush.apppushVideo", data.data.link);
          } else if (type === `action.appPush.attachment`) {
            this.handleChange("action.appPush.name", value.name);
          } else if ((type === "action.appPush.apppushGif", value.name)) {
            this.handleChange("action.appPush.apppushGif", data.data.link);
            this.setState({ appPushGif: data.data.link });
          }
          this.setState({ checkimgUplud: true });
          this.setState({ checkvidUplud: true });
        } else {
          if (
            type == "action.appPush.image-url.src" ||
            type == "action.appPush.image-url.slider[0]"
          ) {
            this.setState({ checkimgUplud: false });
          }
          if (type == "action.appPush.appPushVideo") {
            this.setState({ checkvidUplud: false });
          }
        }
      });
    }
  };

  handleURLValueChange = (label, value) => {
    this.setState({ urlValue: { label, value } });
  };

  setLocalization = (selectedLocalization) => {
    this.setState({ selectedLocalization });
  };

  setVideoValue = (value) => {
    this.handleChange("action.appPush.appPushVideo", value);
  };

  setGifValue = (value) => {
    this.handleChange("action.appPush.apppushGif", value);
  };

  componentWillUnmount() {
    Object.keys(this.props.form).forEach((key) => {
      this.props.destroy(key);
    });
    this.props.clearPending();
    this.props.duplicateCampaign(null);
  }

  nextStep = () => {
    const index = sequence.indexOf(this.state.currentStep);
    this.setState({
      currentStep: sequence[index + 1],
    });
  };

  canGo = (stepToJump) => {
    const { form } = this.props;
    const { data, currentStep } = this.state;
    const index = sequence.indexOf(currentStep);

    const shouldWizardChangeVar = shouldWizardChange(
      sequence[index + stepToJump],
      currentStep,
      sequence,
      data,
      form
    );
    return shouldWizardChangeVar;
  };

  clearGifAppPush = () => {
    this.setState({ appPushGif: "" });
    this.handleChange("apppushGif", "");
  };

  prevStep = () => {
    const index = sequence.indexOf(this.state.currentStep);
    this.setState({
      currentStep: sequence[index - 1],
    });
  };

  isComplete = (id) => {
    const currentIndex = sequence.indexOf(this.state.currentStep);
    const testIndex = sequence.indexOf(id);

    return testIndex < currentIndex;
  };

  canChange = (newStep) => {
    const { form } = this.props;
    const { data, currentStep } = this.state;
    return shouldWizardChange(newStep, currentStep, sequence, data, form);
  };

  // start handling form
  handleChange = (type, value) => {
    if (
      value &&
      (typeof value === "string" || value instanceof String) &&
      value.includes("tenor")
    )
      this.state.fileSizeError = false;

    this.setState((prevState) => ({
      data: handleChangeConfig(
        type,
        value,
        prevState.data,
        this.state.initialized
      ),
    }));
  };

  handleAdvancedClick = (type) => {
    this.setState((prevState) => ({
      advancedOpen: {
        ...prevState.advancedOpen,
        [type]: !prevState.advancedOpen[type],
      },
    }));
  };

  handleSelectOptional = (option, val) => {
    this.setState({
      optionals: {
        [option]: val,
      },
    });
  };

  handleOptionSelectUrl = (label, value) => {
    this.setState({ optionUrlType: { label, value } });
  };

  handleOptionSelectValue = (label, value) => {
    this.setState({ optionUrlValue: { label, value } });
  };

  handleSelectUrl = (label, value) => {
    const { type } = this.state;
    this.setState({ urlType: { label, value } });
    this.handleChange(`action.${type}.url`, undefined);
  };

  handleOptionalLinkSelect = (e) => {
    const { type } = this.state;
    this.setState({ optionalSelectedUrl: e.target.value });
    if (e.target.value) {
      this.handleChange(`action.${type}.url`, e.target.value);
    }
  };

  handleOptionalImageSelect = (e) => {
    const { type } = this.state;
    this.setState({ optionalSelectedUrl: e.target.value });
    if (e.target.value) {
      this.handleChange(`action.${type}.url`, e.target.value);
    }
  };

  handleSegmentSelect = (segment) => {
    this.setState({ selectedSegment: segment });
  };

  handleLinkSelect = (e) => {
    const { type } = this.state;
    this.setState({ selectedUrl: e.target.value });
    if (e.target.value) {
      this.handleChange(`action.${type}.url`, e.target.value);
    }
  };

  handleDeviceSelect = (label, value) => {
    const { type } = this.state;
    this.setState({ selectedDevice: { label, value } });
    if (value) {
      this.handleChange(`action.${type}.deviceType`, value);
    }
  };

  handleSelectTarget = (label, value) => {
    const { type } = this.state;
    this.setState({ targetType: { label, value } });
    this.handleChange(`action.${type}.receivers`, undefined);
    this.handleChange(`action.${type}.deviceType`, undefined);
  };

  toggleSendNow = (newValue) => {
    this.setState({ sendNow: newValue }, this.formatTime);
  };

  toggleRepeat = () => {
    this.setState(
      (prevState) => ({
        repeat: !prevState.repeat,
      }),
      this.formatTime
    );
  };

  handleTimeZoneChange = (newValue) => {
    this.setState(
      (prevState) => ({
        time: { ...prevState.time, timezone: newValue },
      }),
      this.formatTime
    );
  };

  handleStartTimeChange = (moment) => {
    this.setState(
      (prevState) => ({
        time: { ...prevState.time, start: moment },
      }),
      this.formatTime
    );
  };

  handleEndTimeChange = (moment) => {
    this.setState(
      (prevState) => ({
        time: { ...prevState.time, end: moment },
      }),
      this.formatTime
    );
  };

  handleFrequencyChange = (value) => {
    this.setState(
      (prevState) => ({
        time: { ...prevState.time, frequency: value },
      }),
      this.formatTime
    );
  };

  handleSilentPushSequence = (flag) => {
    steps = flag ? silentSteps : default_steps;
  };

  formatTime = () => {
    const {
      repeat,
      sendNow,
      time: { start, end, frequency, timezone },
    } = this.state;

    const startFormat = moment(start).format("YYYY/MM/DD,HH:mm");
    const endFormat = moment(end).format("YYYY/MM/DD,HH:mm");

    if (sendNow) {
      this.handleChange("repeat", undefined);
    } else {
      if (repeat) {
        if (start && end && frequency && timezone?.offset) {
          this.handleChange("repeat", {
            frequency: frequency,
            frequency_start: startFormat + timezone?.offset,
            period_start: startFormat + timezone?.offset,
            period_end: endFormat + timezone?.offset,
          });
        }
      } else {
        if (start && timezone?.offset) {
          this.handleChange("repeat", {
            frequency: "minute",
            frequency_start: startFormat + timezone?.offset,
            period_start: startFormat + timezone?.offset,
            period_end: startFormat + timezone?.offset,
          });
        }
      }
    }
  };

  youtubeParser = (url) => {
    url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return undefined !== url[2] ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
  };

  handleSubmit = () => {
    const {
      routeParams: { subdomain },
    } = this.props;

    const { data } = this.state;

    const {
      pushContentStep,
      pushOptionsStep,
      pushMessageStep,
      pushPublishStep,
    } = this.props.form;

    const body = {
      appPush: {
        message: "",
        campaignName: pushMessageStep.values.action.campaign_name,
      },
      campaign_name: pushMessageStep.values.action.campaign_name,
    };

    if (pushMessageStep.values.type.toLowerCase().includes("text")) {
      body.appPush.selectedType = "text";
      body.appPush.type = "text";
      Object.values(this.state.languages)
        .filter(({ selected }) => selected)
        .forEach(({ code }) => {
          const key = code === "en" ? "message" : `alert-${code}`;
          body.appPush[key] = _.get(pushContentStep, [
            "values",
            "action",
            "appPush",
            `message-${code}`,
          ]);
        });
    }

    body.appPush["localization"] = _.mapKeys(
      this.state.languages,
      (lang, value) => {
        if (
          lang.selected &&
          value &&
          value !== "undefined" &&
          lang !== undefined
        ) {
          return value;
        }
      }
    );

    delete body.appPush["localization"]["undefined"];

    if (pushMessageStep.values.type.toLowerCase().includes("silent")) {
      body.appPush.type = "silent";
      body.appPush.selectedType = "silent";
      body.appPush.op = pushContentStep.values.action.appPush.silent;
      body.appPush.silent = pushContentStep.values.action.appPush.silent;
      body.appPush["content-available"] = 1;
      body.appPush.sound = "";
    }

    if (pushMessageStep.values.type.toLowerCase().includes("url")) {
      body.appPush.type = "url";
      body.appPush.selectedType = "url";
      body.appPush.url = pushContentStep.values.action.appPush.url;
      body.appPush["campaign_id"] = "5c10c8ed6d7d9a000b43edf0";
    }

    if (pushMessageStep.values.type.toLowerCase().includes("youtube")) {
      body.appPush.selectedType = "htmlWebVideo";
      body.appPush.type = "htmlWebView";
      body.appPush.youtubeUrl =
        pushContentStep.values.action.appPush.youtubeUrl;

      body.appPush.orientation = this.state.videoSelection.value;
      body.appPush["mutable-content"] = 1;
      body.appPush["category"] = "rich-apns";
      body.appPush.attachment = `<!DOCTYPE html><html><head><title>Youtube Embed</title><style>.iframe-container{  } .iframe-container iframe{  position: absolute;  top:0;  left: 0;  width: 100%;  height: 100%;}</style></head><body><div class="iframe-container">  <iframe width="560" height="315" src="https://www.youtube.com/embed/${this.youtubeParser(
        pushContentStep.values.action.appPush.youtubeUrl
      )}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  </div></body></html>`;
    }

    if (pushMessageStep.values.type.toLowerCase().includes("video")) {
      body.appPush.selectedType = "video";
      body.appPush.type = "video";
      body.appPush.orientation = this.state.videoSelection.value;
      if (
        typeof pushContentStep.values.action.appPush.appPushVideo === "string"
      ) {
        body.appPush.attachment = data.action.appPush.appPushVideo;

        body.appPush.appPushVideo = data.action.appPush.appPushVideo;
      } else {
        body.appPush.attachment = data.action.appPush.appPushVideo;
        body.appPush.appPushVideo = data.action.appPush.appPushVideo;
      }
      body.appPush["campaign_id"] = "5c10c8ed6d7d9a000b43edf0";
      body.appPush["mutable-content"] = 1;
      body.appPush["category"] = "rich-apns";
    }

    if (pushMessageStep.values.type.toLowerCase().includes("gif")) {
      body.appPush.selectedType = "gif";
      body.appPush.type = "gif";
      body.appPush["apppushGif"] = data.action.appPush.apppushGif;
      body.appPush.attachment = data.action.appPush.apppushGif;
      body.appPush["campaign_id"] = "5c10c8ed6d7d9a000b43edf0";
      body.appPush["mutable-content"] = 1;
      body.appPush["content-available"] = 1;
      body.appPush["category"] = "rich-apns";
    }

    if (pushMessageStep.values.type.toLowerCase().includes("image")) {
      body.appPush["attachment"] = data.action.appPush.attachment;
      body.appPush.selectedType = "photo";
      body.appPush.type = "photo";
      body.appPush["campaign_id"] = "5c10c8ed6d7d9a000b43edf0";
      body.appPush["category"] = "rich-apns";
      body.appPush["mutable-content"] = 1;
    }

    if (pushPublishStep.values.action.appPush.targetType === "deviceType") {
      body.appPush.deviceType =
        pushPublishStep.values.action.appPush.targetTypeDevice;

      body.appPush["targetType"] = "deviceType";
    }

    if (this.state.targetType.value === "userId") {
      body.appPush.receivers =
        pushPublishStep.values.action.appPush.targetUserIds
          .split(",")
          .map((userid) => ({ userID: userid }));
      body.appPush.targetUserIds =
        pushPublishStep.values.action.appPush.targetUserIds;
      body.appPush["targetType"] = "userId";
    }

    if (this.state.targetType.value === "segments") {
      const { value: segmentId } = this.state.selectedSegment;

      body.appPush["targetType"] = "segments";
      body.appPush.receivers = { segmentId };
      body.appPush.segmentId = segmentId;
    }

    if (
      pushMessageStep.values.type.toLowerCase().includes("web") &&
      pushMessageStep.values.type.toLowerCase().includes("view") &&
      !pushMessageStep.values.type.toLowerCase().includes("html")
    ) {
      body.appPush.selectedType = "webView";
      body.appPush.type = "webView";
      body.appPush["mutable-content"] = 1;
      body.appPush.attachment = pushContentStep.values.action.appPush.htmlurl;
      body.appPush.htmlurl = pushContentStep.values.action.appPush.htmlurl;
      body.appPush["campaign_id"] = "5c10c8ed6d7d9a000b43edf0";
      body.appPush["category"] = "rich-apns";
    }

    if (
      pushMessageStep.values.type.toLowerCase().includes("html") &&
      pushMessageStep.values.type.toLowerCase().includes("web") &&
      pushMessageStep.values.type.toLowerCase().includes("view")
    ) {
      body.appPush.selectedType = "htmlWebView";
      body.appPush.type = "htmlWebView";
      body.appPush.attachment =
        pushContentStep.values.action.appPush["image-url"];
      body.appPush.attachment = pushContentStep.values.action.appPush.htmlcode; // modified because abdullah refused to fix it in BE
      body.appPush["campaign_id"] = "5c10c8ed6d7d9a000b43edf0";
      body.appPush["mutable-content"] = 1;
      body.appPush["category"] = "rich-apns";
    }

    if (
      !pushMessageStep.values.type.toLowerCase().includes("silent") &&
      pushOptionsStep.values.optionals &&
      pushOptionsStep.values.optionals.expire_date &&
      pushOptionsStep.values.optionals.expire_date.selected
    ) {
      body.appPush.expiration_time = moment(
        pushOptionsStep.values.optionals.expire_date.value
      ).format("YYYY/MM/DD HH:mm");
    }
    if (!pushMessageStep.values.type.toLowerCase().includes("silent")) {
      Object.values(this.state.languages)
        .filter(({ selected }) => selected)
        .forEach(({ code }) => {
          const key = code === "en" ? "title" : `title-${code}`;
          body.appPush[key] = _.get(pushContentStep, [
            "values",
            "action",
            "appPush",
            `title-${code}`,
          ]);
        });
    }

    if (
      !pushMessageStep.values.type.toLowerCase().includes("silent") &&
      pushOptionsStep.values.optionals &&
      pushOptionsStep.values.optionals.badge &&
      pushOptionsStep.values.optionals.badge.selected
    ) {
      body.appPush.badge = parseInt(
        pushOptionsStep.values.action.appPush.badge
      );
    }

    if (
      !pushMessageStep.values.type.toLowerCase().includes("silent") &&
      pushOptionsStep.values.optionals &&
      pushOptionsStep.values.optionals.custom_sound &&
      pushOptionsStep.values.optionals.custom_sound.selected
    ) {
      body.appPush.sound = pushOptionsStep.values.action.appPush.sound;
    }

    if (
      !pushMessageStep.values.type.toLowerCase().includes("silent") &&
      pushOptionsStep.values.optionals &&
      pushOptionsStep.values.optionals.ios_category &&
      pushOptionsStep.values.optionals.ios_category.selected
    ) {
      body.appPush.ios_category =
        pushOptionsStep.values.optionals.ios_category.value;
    }

    if (
      !pushMessageStep.values.type.toLowerCase().includes("silent") &&
      pushOptionsStep.values.optionals &&
      pushOptionsStep.values.optionals.url &&
      pushOptionsStep.values.optionals.url.selected
    ) {
      body.appPush.call2action =
        pushOptionsStep.values.action.appPush.call2action;
    }

    if (
      !pushMessageStep.values.type.toLowerCase().includes("silent") &&
      pushOptionsStep.values.optionals
    ) {
      body.appPush.optionals = pushOptionsStep.values.optionals;
    }

    if (!pushMessageStep.values.type.toLowerCase().includes("silent")) {
      if (data.notification_image && data.notification_image.src) {
        body.appPush["image-url"] =
          pushContentStep.values.action.appPush[`image-url`];

        body.appPush["category"] = "rich-apns";
      }
    }

    if (
      pushMessageStep.values.type.toLowerCase().includes("image") &&
      pushContentStep.values.action.appPush.url
    ) {
      body.appPush.url = pushContentStep.values.action.appPush.url;
    }
    body.url = data.url;
    body.title = "sending appPush";
    body.action = {};
    body.action.appPush = _.cloneDeep(body.appPush);

    if (body.action.appPush.url)
      body.action.appPush.url = body.action.appPush.url.trim();

    if (
      body.action.appPush.attachment &&
      typeof body.action.appPush.attachment === "string"
    )
      body.action.appPush.attachment = body.action.appPush.attachment.trim();

    if (
      body.action.appPush.call2action &&
      typeof body.action.appPush.call2action === "string"
    )
      body.action.appPush.call2action = body.action.appPush.call2action.trim();

    body.action.campaign_name = body.campaign_name;
    body.authenticatin_header = data.authenticatin_header;
    delete body.appPush;
    delete body.campaign_name;

    if (data.repeat) {
      body.repeat = data.repeat;
    }

    this.props
      .sendPushScheduler(data.authenticatin_header, body)
      .then(() => {
        this.context.router.push(`/app/${subdomain}/campaignssummary`);
      })
      .then(() => {
        this.props.showNotification(
          {
            title: "",
            message: I18n.t("createAppPushPage_sentMsg"),
            position: "bc",
            autoDismiss: 5,
          },
          "success"
        );
      });
  };

  changeValidationState = (condition) => {
    this.setState({ validation: condition });
  };

  handleSelectTemplate = (code, selectedTemplate) => {
    this.setState({
      selectedTemplate: {
        ...this.state.selectedTemplate,
        [code]: selectedTemplate,
      },
    });
  };

  renderPreview = () => {
    const data =
      _.get(this.props.form, ["pushMessageStep", "values", "type"]) ===
      "Silent Push"
        ? ""
        : _.get(
            this.props.form,
            [
              "pushContentStep",
              "values",
              "action",
              "appPush",
              `message-${this.state.selectedLocalization.code}`,
            ],
            ""
          );
    const dataTitle =
      _.get(this.props.form, ["pushMessageStep", "values", "type"]) ===
      "Silent Push"
        ? ""
        : _.get(
            this.props.form,
            [
              "pushContentStep",
              "values",
              "action",
              "appPush",
              `title-${this.state.selectedLocalization.code}`,
            ],
            ""
          );
    return <IOSPreview type="appPush" data={data} title={dataTitle} />;
  };

  renderStepContent = () => {
    const {
      type,
      data,
      advancedOpen,
      urlType,
      targetType,
      selectedUrl,
      selectedDevice,
      sendNow,
      repeat,
      time,
      currStepIdx,
      appPushGif,
      selectedType,
    } = this.state;

    const linkreturned = data;

    switch (steps[currStepIdx - 1].value) {
      case "info":
        return (
          <div className={classes["apppush-content"]}>
            <div>
              <div style={{ maxWidth: "700px" }}>
                <PushMessageStep
                  initialValues={data}
                  type={type}
                  handleChange={this.handleChange}
                  handleAdvancedClick={this.handleAdvancedClick}
                  advancedOpen={advancedOpen}
                  config={data}
                  handleSilentPushSequence={this.handleSilentPushSequence}
                  urlType={urlType}
                  targetType={targetType}
                  selectedUrl={selectedUrl}
                  selectedDevice={selectedDevice}
                  handleSelectUrl={this.handleSelectUrl}
                  handleLinkSelect={this.handleLinkSelect}
                  handleSelectTarget={this.handleSelectTarget}
                  handleDeviceSelect={this.handleDeviceSelect}
                  myuserId={myuserId}
                  selectedType={selectedType}
                />
              </div>
            </div>
            <div>{this.renderPreview()}</div>
          </div>
        );

      case "content":
        return (
          <div style={{ background: "#fff" }}>
            {this.props.form.pushMessageStep.values.type !== "Silent Push" && (
              <LanguageSelector
                onClickLanguage={this.onClickLanguage}
                setLocalization={this.setLocalization}
                selectedLocalization={this.state.selectedLocalization}
                languages={this.state.languages}
              />
            )}

            <div className={classes["apppush-content"]}>
              <div>
                <PushContentStep
                  initialValues={data}
                  type={type}
                  fileSizeError={this.state.fileSizeError}
                  videoSelection={this.state.videoSelection}
                  changeVideoOrientation={this.changeVideoOrientation}
                  languages={this.state.languages}
                  selectedLocalization={this.state.selectedLocalization}
                  selectedTemplate={
                    this.state.selectedTemplate[
                      this.state.selectedLocalization.code
                    ]
                  }
                  handleSelectTemplate={this.handleSelectTemplate}
                  clearGifAppPush={this.clearGifAppPush}
                  handleURLValueChange={this.handleURLValueChange}
                  urlValue={this.state.urlValue}
                  handleChange={this.handleChange}
                  handleAdvancedClick={this.handleAdvancedClick}
                  advancedOpen={advancedOpen}
                  config={data}
                  appPushGif={appPushGif}
                  changeLocalization={this.changeLocalization}
                  localization={this.state.localization}
                  urlType={urlType}
                  validation={this.state.validation}
                  funcUpdateState={this.changeValidationState}
                  imgLoading={this.state.imgLoading}
                  vidLoading={this.state.vidLoading}
                  handleUploadImg={this.handleUploadImg}
                  clearImgur={this.clearImgur}
                  targetType={targetType}
                  setVideoValue={this.setVideoValue}
                  setGifValue={this.setGifValue}
                  selectedUrl={selectedUrl}
                  linkreturned={linkreturned}
                  selectedDevice={selectedDevice}
                  handleSelectUrl={this.handleSelectUrl}
                  handleLinkSelect={this.handleLinkSelect}
                  handleSelectTarget={this.handleSelectTarget}
                  handleDeviceSelect={this.handleDeviceSelect}
                  myuserId={myuserId}
                  urlTypeForOptionalImage={this.state.urlTypeForOptionalImage}
                  urlValueForOptionalImage={this.state.urlValueForOptionalImage}
                  handleOptionalUrlImageSelect={
                    this.handleOptionalUrlImageSelect
                  }
                  handleOptionalUrlImageSelectType={
                    this.handleOptionalUrlImageSelectType
                  }
                />
              </div>
              <div>{this.renderPreview()}</div>
            </div>
          </div>
        );

      case "options":
        return (
          <div className={classes["apppush-content"]}>
            <div>
              <PushOptionsStep
                initialValues={data}
                type={type}
                selectedUrl={this.state.optionalSelectedUrl}
                handleLinkSelect={this.handleOptionalLinkSelect}
                handleChange={this.handleChange}
                config={data}
                handleSelectOptional={this.handleSelectOptional}
                optionals={this.state.optionals}
                sendNow={sendNow}
                toggleSendNow={this.toggleSendNow}
                repeat={repeat}
                toggleRepeat={this.toggleRepeat}
                handleTimeZoneChange={this.handleTimeZoneChange}
                optionsLoaded={this.state.data.optionsLoaded}
                handleStartTimeChange={this.handleStartTimeChange}
                handleEndTimeChange={this.handleEndTimeChange}
                handleFrequencyChange={this.handleFrequencyChange}
                time={time}
                handleOptionSelectValue={this.handleOptionSelectValue}
                handleOptionSelectUrl={this.handleOptionSelectUrl}
                urlType={this.state.optionUrlType}
                urlValue={this.state.optionUrlValue}
              />
            </div>
            <div>{this.renderPreview()}</div>
          </div>
        );
      case "publish":
        return (
          <div className={classes["apppush-content"]}>
            <div>
              <PushPublishStep
                initialValues={data}
                type={type}
                timeObj={this.state.time}
                handleChange={this.handleChange}
                config={data}
                segments={this.props.segments}
                sendNow={sendNow}
                toggleSendNow={this.toggleSendNow}
                repeat={repeat}
                toggleRepeat={this.toggleRepeat}
                handleTimeZoneChange={this.handleTimeZoneChange}
                handleStartTimeChange={this.handleStartTimeChange}
                handleEndTimeChange={this.handleEndTimeChange}
                handleFrequencyChange={this.handleFrequencyChange}
                time={time}
                selectedSegment={this.state.selectedSegment}
                handleSegmentSelect={this.handleSegmentSelect}
                advancedOpen={advancedOpen}
                urlType={urlType}
                targetType={targetType}
                selectedUrl={selectedUrl}
                frequency={this.state.time.frequency}
                selectedDevice={selectedDevice}
                handleSelectUrl={this.handleSelectUrl}
                handleLinkSelect={this.handleLinkSelect}
                handleSelectTarget={this.handleSelectTarget}
                handleDeviceSelect={this.handleDeviceSelect}
                myuserId={myuserId}
                refetchSegments={this.refetchSegments}
              />
            </div>
            <div>{this.renderPreview()}</div>
          </div>
        );
    }
  };

  canGoBack = () => {
    if (this.state.fileSizeError) return false;
    return true;
  };

  canStepChange() {
    const { form } = this.props;
    const {
      currStepIdx,
      sendNow,
      repeat,
      imgLoading,
      vidLoading,
      time: { start, end, timezone },
    } = this.state;

    if (this.state.fileSizeError) return false;

    if (imgLoading.apppushGif) return false;

    if (vidLoading && vidLoading["action.appPush.appPushVideo"]) return false;

    const curStep = steps[currStepIdx - 1].value;
    if (form) {
      if (form.pushMessageStep && curStep === "info") {
        if (form.pushMessageStep.syncErrors) {
          return false;
        } else {
          return true;
        }
      }
      if (form.pushContentStep && curStep === "content") {
        // check all language message are filled
        const invalid = Object.values(this.state.languages)
          .filter(({ selected }) => selected)
          .some(
            ({ code }) =>
              !_.get(form.pushContentStep, [
                "values",
                "action",
                "appPush",
                `message-${code}`,
              ])
          );
        if (
          form.pushContentStep.syncErrors ||
          (form.pushMessageStep.values.type !== "Silent Push" && invalid)
        ) {
          return false;
        } else {
          return true;
        }
      }
      if (form.pushOptionsStep && curStep === "options") {
        const optionals = form.pushOptionsStep.values.action.appPush.optionals;
        const keys = ["title", "custom_sound", "ios_category", "badge", "url"];
        for (let i = 0; i < keys.length; ++i) {
          if (
            _.get(optionals, [keys[i], "selected"]) &&
            _.get(form, ["pushOptionsStep", "syncErrors", "temp", keys[i]])
          ) {
            return false;
          }
        }
        if (
          optionals &&
          optionals.expire_date &&
          optionals.expire_date.selected &&
          !optionals.expire_date.value
        ) {
          return false;
        }
        return true;
      }
      if (form.pushPublishStep && curStep === "publish") {
        let isValidSendLater = null;

        if (sendNow) {
          isValidSendLater = true;
        } else {
          if (repeat) {
            isValidSendLater = start && end && timezone;
          } else {
            isValidSendLater = start && timezone;
          }
        }
        if (!isValidSendLater) return false;

        if (
          form.pushPublishStep.syncErrors ||
          (_.get(form.pushPublishStep, [
            "values",
            "action",
            "appPush",
            "targetType",
          ]) === "segments" &&
            !_.get(form.pushPublishStep, [
              "values",
              "action",
              "appPush",
              "segmentId",
            ])) ||
          (_.get(form.pushPublishStep, [
            "values",
            "action",
            "appPush",
            "targetType",
          ]) === "userId" &&
            !_.get(form.pushPublishStep, [
              "values",
              "action",
              "appPush",
              "targetUserIds",
            ]))
        ) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    }
  }

  changeCurrentStep = (value) => {
    const newIdx = steps.map(({ value }) => value).indexOf(value) + 1;
    const currIdx = this.state.currStepIdx;
    if (currIdx > newIdx) {
      // To go back
      this.setState({ currStepIdx: newIdx });
    } else if (newIdx - currIdx === 1) {
      // at max to go one step forward
      if (this.canStepChange()) {
        this.setState({ currStepIdx: newIdx });
      }
    }
  };

  render() {
    const {
      params,
      config: { isPending },
    } = this.props;

    const { loading, currStepIdx } = this.state;

    if (loading || isPending) {
      return <SpinLoader />;
    } else
      return (
        <div className={classes.createAppPush}>
          <div className={classes.header}>
            <NewBreadcrumb fullPath={["campaigns", "RichPush", "new"]} />
          </div>

          <div className={classes.content}>
            <CampaignWizard
              steps={steps}
              currentStep={currStepIdx}
              changeCurrentStep={this.changeCurrentStep}
            >
              {this.renderStepContent()}
            </CampaignWizard>
          </div>

          <div className={classes.createAppPushBtnsContainer}>
            <PrimaryButton
              value={
                currStepIdx === steps.length
                  ? `${I18n.t("submitBtn")}`
                  : `${I18n.t("nextBtn")}`
              }
              disabled={!this.canStepChange()}
              onClick={() => {
                currStepIdx === steps.length
                  ? this.handleSubmit()
                  : this.changeCurrentStep(steps[currStepIdx].value);
              }}
            />
            {currStepIdx !== 1 && (
              <SecondaryButton
                style={{ marginRight: "1rem" }}
                value={I18n.t("backBtn")}
                disabled={!this.canGoBack()}
                onClick={() =>
                  this.changeCurrentStep(steps[currStepIdx - 2].value)
                }
              />
            )}
            <SecondaryButton
              style={{ marginRight: "1rem" }}
              value={I18n.t("cancelBtn")}
              onClick={() =>
                this.context.router.push(
                  `/app/${params.subdomain}/newcampaigns`
                )
              }
            />
          </div>
        </div>
      );
  }
}
export default connect(
  (store) => ({
    user: store.user,
    templates: store.template,
    config: store.appPush,
    form: store.form,
    push: store.push,
    segments: store.CustomerSegment.SegmentsObj,
    notifications: store.notifications,
  }),
  {
    getSuitLandingPages,
    getSuitSmartlinks,
    getSuitTemplates,
    sendPush,
    clearPending,
    destroy,
    sendPushScheduler,
    getSuitCustomerSegments,
    getAppBackendConfig,
    getCurrentSuit,
    ReduxChange,
    showNotification: Notifications.show,
    duplicateCampaign,
    getNotifyConfig,
  }
)(CreatePushContainer);
