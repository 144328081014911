import {
  IS_WELCOME_SKIPPED,
  USER_DATA,
  USER_IS_LOGGED_IN,
  USER_SUITS,
} from "consts/local";
import { CHARITY_APP, MOBILE_APP, RETAIL_GAIN } from "consts/suitTypes";
import _ from "lodash";
import {
  deleteFromLocalStore,
  getFromLocalStore,
  setToLocalStore,
} from "store/localStore";
import { browserHistory } from "../main";
import { Toast } from "./toast";

/**
 * Updates the user data in local storage.
 * @param {Object} data - The updated user data.
 */
export const updateUser = (data) => setToLocalStore(USER_DATA, data);

/**
 * Checks if the user is logged in by verifying the presence of user login status and user data in local storage.
 * @returns {boolean} Returns true if the user is logged in, false otherwise.
 */
export const isLoggedIn = () =>
  getFromLocalStore(USER_IS_LOGGED_IN) && getFromLocalStore(USER_DATA);

/**
 * Retrieves user data from local storage.
 *
 * @returns {Object} The user data object.
 */
export const userData = () => getFromLocalStore(USER_DATA);

/**
 * Logs in the user and sets the necessary data to local storage.
 * @param {Object} data - The user data to be stored in local storage.
 */
export const login = (data) => {
  setToLocalStore(USER_IS_LOGGED_IN, true);
  setToLocalStore(USER_DATA, {
    ...userData(),
    ...data,
  });
  setToLocalStore(IS_WELCOME_SKIPPED, false);
};

/**
 * Deletes user data from local storage upon logout.
 * @function
 * @returns {void}
 */
export const logout = () =>
  deleteFromLocalStore([USER_IS_LOGGED_IN, USER_DATA, USER_SUITS, "schema"]);

export const logoutUser = () => {
  logout();

  // redirect to login page with a reload to clear the redux store and reset the app
  browserHistory.push("/auth/login");

  // display a notification to the user
  Toast.error("Your session has expired. Please login again.");
};

/**
 * Sets initial user data to local storage.
 * @param {Object} userData - The user data object.
 * @param {string} userData.email - The user's email.
 * @param {string} userData.password - The user's password.
 */
export const setInitData = ({ email, password }) => {
  setToLocalStore(USER_DATA, {
    email,
    password,
  });
};

/**
 * Returns the value of the specified property from the user data stored in local storage.
 * @param {string} prop - The name of the property to retrieve.
 * @returns {*} - The value of the specified property.
 */
export const getUserProp = (prop) => {
  const userData = getFromLocalStore(USER_DATA);
  return userData[prop];
};

/**
 * Sets the user suits in local storage.
 * @param {Array} suits - The suits to be set in local storage.
 */
export const setUserSuits = (suits) => {
  setToLocalStore(USER_SUITS, suits);
};

/**
 * Retrieves user suits from local storage.
 * @returns {Array} An array of user suits.
 */
export const userSuits = () => getFromLocalStore(USER_SUITS);

/**
 * Returns the subdomain of a suit with the given ID.
 * @param {string} suitId - The ID of the suit to get the subdomain for.
 * @param {Array<Object>} suits - An array of suit objects to search through.
 * @returns {(string|boolean)} - The subdomain of the suit if found, otherwise false.
 */
export const getSubdomainFromId = (suitId, suits) => {
  const suit = suits.find((suit) => suit.id === suitId);
  return suit ? suit.subdomain : false;
};

/**
 * Returns the ID of a suit based on its subdomain.
 *
 * @param {string} suitSubdomain - The subdomain of the suit.
 * @param {Array} suits - An array of suits.
 * @returns {(string|boolean)} - The ID of the suit if found, false otherwise.
 */
export const getIdFromSubdomain = (suitSubdomain, suits) => {
  const suit = suits.find((suit) => suit.subdomain === suitSubdomain);
  return suit ? suit.id : false;
};

/**
 * Returns the active suit object from an array of suits based on the provided suit ID.
 * @param {string} suitId - The ID of the suit to find.
 * @param {Array<Object>} suits - An array of suit objects to search through.
 * @returns {Object} - The active suit object, or an empty object if not found.
 */
export const getActiveSuit = (suitId, suits) =>
  suits.find((suit) => suit.id === suitId) || {};

/**
 * Returns the first app object from the USER_SUITS array in local storage that matches the given subdomain.
 * @param {string} subdomain - The subdomain to search for.
 * @returns {Object} - The app object that matches the given subdomain, or undefined if no match is found.
 */
export const getSuitBySubdomain = (subdomain) => {
  const apps = getFromLocalStore(USER_SUITS);
  return (
    apps &&
    subdomain &&
    apps.find((app) => app.subdomain.toLowerCase() === subdomain.toLowerCase())
  );
};

/**
 * Returns the suit object with the given ID from the user's suits list.
 * @param {string} suitId - The ID of the suit to retrieve.
 * @returns {Object} - The suit object with the given ID, or undefined if not found.
 */
export const getSuitById = (suitId) => {
  const apps = getFromLocalStore(USER_SUITS);
  return apps.filter((app) => app.id === suitId)[0];
};

/**
 * Returns the subdomain of a user's suit based on the suit ID.
 * @param {string} suitId - The ID of the suit to get the subdomain for.
 * @returns {(string|boolean)} - The subdomain of the suit if found, false otherwise.
 */
export const getSubdomainFromSuitID = (suitId) => {
  const suits = getFromLocalStore(USER_SUITS);
  const suit = suits.find((suit) => suit.id === suitId);
  return suit ? suit.subdomain : false;
};

/**
 * Returns the user suits from local storage.
 * @returns {Array} An array of user suits.
 */
export const getUserSuits = () => {
  return getFromLocalStore(USER_SUITS);
};

/**
 * Shows a notification message indicating that the user is not authorized.
 */
export const showNotAuthorizedMsg = () => {
  Toast.error("Not authorized, contact your account admin ");
};

/**
 * Checks if the current user is a sub-user.
 * @returns {boolean} Returns true if the user is a sub-user, false otherwise.
 */
export const isSubUser = () => {
  return userData() && !!userData().admin_id;
};

/**
 * Checks if a sub user has read/write access to a specific product in a given suit.
 *
 * @param {string} suitId - The ID of the suit to check.
 * @param {string} productName - The name of the product to check.
 * @returns {boolean} - Returns true if the sub user has read/write access to the product, false otherwise.
 */
export const hasSubUserAccessToProduct = (suitId, productName) => {
  if (!userData().projects_products) return false;
  const suitProds = _.find(
    userData().projects_products,
    ({ suit_id }) => suit_id === suitId
  );
  if (!suitProds) return false;
  const productObj = _.find(
    suitProds.products,
    ({ name }) => name === productName
  );
  return _.get(productObj, ["permission"], false) === "read/write";
};

/**
 * Checks if a user has a specific product in their plan.
 * @param {string} suitId - The ID of the user's suit.
 * @param {string} productName - The name of the product to check for.
 * @returns {boolean} - Returns true if the user has the specified product, false otherwise.
 */
export const hasProduct = (suitId, productName, props = null) => {
  if (!isLoggedIn() || !suitId) return false;

  const suits = JSON.parse(localStorage.getItem("user.suits"));
  if (!suits) {
    if (props) showNotAuthorizedMsg();

    return false;
  }
  const suit = suits.find((suit) => suit.id === suitId);
  if (!suit) {
    if (props) showNotAuthorizedMsg();
    return false;
  }
  const planProducts = suit.plan_summary.plan_product;
  for (let product of planProducts) {
    if (product.name == productName) return true;
  }
  if (props) showNotAuthorizedMsg();
  return false;
};

/**
 * Checks if the user has access to a specific product.
 * @param {string} suitId - The ID of the suit.
 * @param {string} productName - The name of the product.
 * @param {Object} [props=null] - Optional props object.
 * @returns {boolean} - Returns true if the user has access to the product, false otherwise.
 */
export const hasAccessToProduct = (suitId, productName, props = null) => {
  if (!hasProduct(suitId, productName)) {
    if (props) showNotAuthorizedMsg();
    return false;
  }

  let hasAccess =
    !isSubUser() ||
    (isSubUser() && hasSubUserAccessToProduct(suitId, productName));

  if (props) {
    if (!hasAccess) {
      showNotAuthorizedMsg();
      return false;
    }
  }
  return hasAccess;
};

/**
 * Identify the suit type based on the offers values.
 * @param {string} offers_values - The offers values to be checked.
 * @returns {string} - The suit type. One of the following: MOBILE_APP, RETAIL_GAIN, CHARITY_APP.
 */
export const identifySuitType = (offers_values) => {
  let suitType = MOBILE_APP;

  if (offers_values) {
    const lowerCasedOffers = offers_values.toLowerCase();

    if (lowerCasedOffers === "smart retargeting") {
      suitType = RETAIL_GAIN;
    } else if (lowerCasedOffers === "marketing links") {
      suitType = CHARITY_APP;
    } else if (
      lowerCasedOffers === "app backend" ||
      lowerCasedOffers === "app boost" ||
      lowerCasedOffers === "mobile attribution"
    ) {
      suitType = MOBILE_APP;
    }
  }
  console.log("ofer" + offers_values + "--suit-type:" + suitType);
  return suitType;
};
